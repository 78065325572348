import React from 'react';
import ScrollingPage from './ScrollToSection';

const LandingPage = () => {
  return (
    <>
      <div className='container'>
        <ScrollingPage />
      </div>
    </>
  );
};

export default LandingPage;
